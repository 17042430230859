import { LampContainer } from '#app/components/ui/lamp.tsx';
import Logo from '../assets/logo.jpg';
import { motion } from "framer-motion";

export default function Index() {
	return (
		<main className=" bg-black min-h-screen flex flex-col justify-center items-center gap-5">
			<LampContainer>
			<div className='flex gap-2'>
				<img src={Logo} alt="Logo" className="mx-auto w-28 h-28" />
				<h1 className="text-3xl font-bold text-orange-400 mt-10">RACROTECH ABRASIVES </h1>
			</div>
				<motion.h1
					initial={{ opacity: 0.5, y: 100 }}
					whileInView={{ opacity: 1, y: 0 }}
					transition={{
						delay: 0.3,
						duration: 0.8,
						ease: "easeInOut",
					}}
					className="mt-8 bg-gradient-to-br from-slate-300 to-slate-500 py-4 bg-clip-text text-center text-4xl font-medium tracking-tight text-transparent md:text-7xl"
				>

					<h1 className="text-4xl font-bold text-white text-center transition duration-150 ease-in-out">This site is under development</h1>
					<p className="text-white text-center text-3xl">Please check back later</p>
				</motion.h1>
			</LampContainer>
		</main>
	)
}
